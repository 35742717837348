.back_backround {
    z-index: -2;
    background: linear-gradient(113deg, #2D3139 0%, #181C27 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.background {
    left: 0;
    right: 0;
    background: linear-gradient(113deg, #2D3139 0%, #181C27 100%);
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
    z-index: -1;
    transition: opacity 2s;
}

.bubbles {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bubbles span {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, #516A9C 0%, rgba(59, 60, 67, 0.00) 100%);
    animation: animate 25s linear infinite;
    animation-duration: calc(225s / var(--i));
}

.bubbles span:nth-child(even)
{
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, #335C83 0.01%, rgba(59, 60, 67, 0.00) 100%);
}

@keyframes animate {
    0% {
        transform: translateY(100vh) scale(0);
    }
    100% {
        transform: translateY(-20vh) scale(0.5);
    }

}

