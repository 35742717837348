.joke {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.5);
    color: white;
    font-size: 24px;
    font-weight: normal;
    z-index: 1000;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}
