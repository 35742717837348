.ball {
    animation: blink 0.2s infinite;
    animation-fill-mode: both;
}
.ball:nth-child(even) {
    animation: blink 0.4s infinite;
    animation-fill-mode: both;
}
.ball:nth-child(2), .ball:nth-child(7), .ball:nth-child(11), .ball:nth-child(14){
    animation: blink 1s infinite;
    animation-fill-mode: both;
}

@keyframes blink {
    0% { opacity: 0; filter:brightness(0) }
    50% { opacity: 1; filter:brightness(3) }
    100% { opacity: 0; filter:brightness(0) }
}

