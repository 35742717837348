.flipping_card {
    width: 200px;
    height: 305px;
    perspective: 1000px; /* додає ефект глибини під час перевертання */

    &.flip {
        >.front {
            transform: rotateY(180deg);
        }
        >.back {
            opacity: 1;
            transform: rotateY(0deg);
        }
    }

    >.front,
    >.back {
        backface-visibility: hidden; /* приховує задню сторону під час перевертання */
        transition: transform 1s; /* змінює швидкість перевертання */
        position: absolute;
    }
}
.front {
    width: 200px;
    height: 305px;
    position: absolute;
    background-image: url("../images/card.png");
    background-size: cover;
    background-position: center;
}

.back {
    transform: rotateY(-180deg);
    background-color: #2D3139;
    width: 200px;
    height: 305px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

text {
    color: white;
    max-width: 90%;
    text-align: center;
    font-family: Montserrat;
}